export default [
  {
    path: "/gallery",
    component: () => import("@/views/global/Index"),
    children: [
      {
        path: "",
        name: "gallery.index",
        component: () => import("@/views/app/Gallery"),
        meta: { layout: "HorizontalBar" },
      },
      {
        path: "*",
        redirect: "/gallery",
      },
    ],
  },
  {
    path: "/shop",
    component: () =>
      import(/* webpackChunkName: "shop" */ "@/views/global/Index"),

    children: [
      {
        path: "",
        name: "shop.index",
        component: () =>
          import(/* webpackChunkName: "shop" */ "@/views/shop/Index"),
        meta: { layout: "HorizontalBar" },
      },
      {
        path: ":orgSlug",
        name: "shop.organization",
        component: () =>
          import(/* webpackChunkName: "shop" */ "@/views/shop/Organization"),
        meta: { layout: "HorizontalBar" },
      },
      {
        path: ":orgSlug/:partyId",
        name: "shop.party",
        component: () =>
          import(/* webpackChunkName: "shop" */ "@/views/shop/Party"),
        meta: { layout: "HorizontalBar" },
      },
      {
        path: ":orgSlug/:partyId/gallery",
        name: "shop.party.gallery",
        component: () =>
          import(
            /* webpackChunkName: "shop" */ "@/views/shop/PartyFullGallery"
          ),
        meta: { layout: "HorizontalBar", hiddenAppBar: true },
      },
      {
        path: "*",
        redirect: "/app",
      },
    ],
  },
];
