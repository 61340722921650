import authenticate from "@/auth/authenticate";

export default [
  {
    path: "/staff",
    component: () =>
      import(/* webpackChunkName: "app" */ "@/views/global/Index"),
    // beforeEnter: authenticate,

    children: [
      {
        path: "",
        name: "app.index",
        redirect: "/app/ticket",
      },
      {
        path: "scanner",
        name: "staff.scanner",
        meta: { layout: "Blank" },
        component: () =>
          import(
            /* webpackChunkName: "staff" */ "@/views/staff/scanner/ScannerInit"
          ),
      },
      {
        path: "scanner/:session",
        name: "staff.scanner.session",
        meta: { layout: "Blank" },
        component: () =>
          import(
            /* webpackChunkName: "staff" */ "@/views/staff/scanner/ScannerSession"
          ),
      },
      {
        path: "*",
        redirect: "/app",
      },
    ],
  },
];
