import http from "@/http-common";

const myFiliations = async () => {
  return await http
    .get(`/app/membership`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getFiliationById = async (id) => {
  return await http
    .get(`/app/membership/${id}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  myFiliations,
  getFiliationById,
};
