import http from "@/http-common";

const upload = (orgId, partyId, file) => {
  if (!file) throw new Error("file is required");

  var formData = new FormData();
  formData.append("description", file.description);
  delete file.description;
  formData.append("file", file);

  return http
    .post(`/admin/organization/${orgId}/party/${partyId}/gallery`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((r) => r.data)
    .catch((e) => {
      throw e.response.data || e;
    });
};

const getAll = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/gallery`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const update = async (orgId, partyId, galleryId, data) => {
  return await http
    .put(
      `/admin/organization/${orgId}/party/${partyId}/gallery/${galleryId}`,
      data
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const destroy = async (orgId, partyId, galleryId) => {
  return await http
    .delete(
      `/admin/organization/${orgId}/party/${partyId}/gallery/${galleryId}`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  upload,
  getAll,
  update,
  destroy,
};
