import store from "../store";

export default (to, from, next) => {
  const selectedOrganization =
    store.getters["organization/selectedOrganization"];

  try {
    if (!selectedOrganization) {
      return next({
        path: "/admin",
      });
    }

    if (to.meta.permission) {
      const userHasPermission = store.getters["auth/hasPermission"];
      if (!userHasPermission(to.meta.permission)) throw new Error();
    }

    next();
  } catch {
    return next({ path: "/admin" });
  }
};
