<template>
  <div>
    <label>{{ label }}</label>
    <div :id="holder" class="text-start text--black" />
  </div>
</template>

<script>
import Editor from "@toast-ui/editor";
import "@toast-ui/editor/dist/i18n/pt-br";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor/dist/theme/toastui-editor-dark.css";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";

export default {
  name: "base-editor",
  props: {
    value: {
      type: String,
      require: true,
    },
    holder: {
      type: String,
      default: () => "toast-editor",
      require: true,
    },
    options: {
      type: Object,
      default: () => ({}),
      require: true,
    },
    initialized: {
      type: Function,
      default: () => {},
    },
    label: {
      type: String,
      default: () => "",
    },
  },
  data: () => ({
    editor: null,
  }),
  methods: {
    reloadEditor() {
      let bkpData = this.editor.getMarkdown();
      this.initEditor({ ...this.options, initialValue: bkpData });
    },
    initEditor(props) {
      this.destroyEditor();
      this.editor = new Editor({
        language: "pt-br",
        useCommandShortcut: true,
        usageStatistics: false,
        toolbarItems: [
          ["heading", "bold", "italic", "strike"],
          ["hr", "quote"],
          ["ul", "ol", "task", "indent", "outdent"],
          ["table"],
        ],
        theme: this.theme,
        hideModeSwitch: true,
        minHeight: "150x",
        initialEditType: "wysiwyg",
        ...props,
        el: document.querySelector(`#${this.holder}`),
        events: {
          change: () => {
            this.$emit("input", this.editor.getMarkdown());
          },
        },
      });
    },

    destroyEditor() {
      if (this.editor) {
        this.editor.destroy();
        this.editor = null;
      }
    },
  },
  computed: {
    theme() {
      return this.$vuetify.theme.isDark ? "dark" : "default";
    },
  },
  watch: {
    value: {
      handler: function (val) {
        if (this.editor && val !== this.editor.getMarkdown()) {
          this.editor.setMarkdown(val || "");
        }
      },
    },
    theme() {
      this.reloadEditor();
    },
  },
  mounted() {
    this.initEditor({ ...this.options, initialValue: this.value || "" });
  },
};
</script>
