// Vuetify Documentation https://vuetifyjs.com

import Vue from "vue";
import Vuetify from "vuetify/lib";
import { themePreset } from "../../src/themeConfig";

Vue.use(Vuetify);

import pt from "vuetify/lib/locale/pt";

export default new Vuetify({
  theme: themePreset.theme,
  lang: {
    locales: { pt },
    current: "pt",
  },
});
