import http from "@/http-common";

const create = async (data) => {
  return await http
    .post("/admin/organization", data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const update = async (orgId, data) => {
  return await http
    .put(`/admin/organization/${orgId}`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getAll = async () => {
  return await http
    .get("/admin/organization")
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const mySales = async (orgId) => {
  return await http
    .get(`/admin/organization/${orgId}/my-sales`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const fees = async (orgId) => {
  return await http
    .get(`/admin/organization/${orgId}/fees`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const feePayments = async (orgId) => {
  return await http
    .get(`/admin/organization/${orgId}/fee-payments`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const cover = (orgId, file) => {
  if (!file) throw new Error("file is required");

  var formData = new FormData();
  formData.append("file", file);

  return http
    .put(`/admin/organization/${orgId}/cover`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((r) => r.data)
    .catch((e) => {
      throw e.response.data || e;
    });
};

const photo = (orgId, file) => {
  if (!file) throw new Error("file is required");

  var formData = new FormData();
  formData.append("file", file);

  return http
    .put(`/admin/organization/${orgId}/photo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((r) => r.data)
    .catch((e) => {
      throw e.response.data || e;
    });
};

const members = async (orgId) => {
  return await http
    .get(`/admin/organization/${orgId}/member`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const updateMember = async (orgId, data) => {
  return await http
    .put(`/admin/organization/${orgId}/member`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const removeMember = async (orgId, userId) => {
  return await http
    .delete(`/admin/organization/${orgId}/member/${userId}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const grantSearch = async (orgId, query) => {
  return await http
    .get(`/admin/organization/${orgId}/member/granting-organization`, {
      params: { q: query },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const grantUpdateOrganization = async (orgId, data) => {
  return await http
    .put(`/admin/organization/${orgId}/member/granting-organization`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const grantRemoveOrganization = async (orgId, id) => {
  return await http
    .delete(`/admin/organization/${orgId}/member/granting-organization/${id}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

import party from "./party";

export default {
  create,
  update,
  getAll,
  mySales,
  fees,
  feePayments,
  members,
  grantAccess: {
    search: grantSearch,
    update: grantUpdateOrganization,
    remove: grantRemoveOrganization,
  },
  updateMember,
  removeMember,
  cover,
  photo,
  party,
};
