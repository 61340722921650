import store from "../store";

export default (to, from, next) => {
  var isPwa = false
  if (to.hash || to.query.pwa) {
    if (to.hash.startsWith('#twa')) {
      isPwa = true
      window.sessionStorage.setItem('twa', true)
    }
    if (to.query.pwa) {
      isPwa = true
      window.sessionStorage.setItem('pwa', true)
    }
  }



  if (!store.getters["auth/isAuthenticated"]) {
    return next({
      path: "/auth",
      query: { redirect: to.fullPath },
    });
  } else if (isPwa) {
    if (isPwa) {
      const initOnAdmin = window.localStorage.getItem("initOnAdmin") === "true";
      if (initOnAdmin)
        return next({name: "admin.index"});
    }
  }

  next();
};
