import Vue from "vue";
import Vuex from "vuex";
import ORGANIZATION from "@/services/admin/organization";
import MEMBERSHIP from "@/services/admin/membership";
import hasFlag from "@/utils/hasFlag";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    organizations: [],
    selectedOrganization: null,
    parties: {},
    memberships: {},
  },
  getters: {
    selectedOrganization: (state) =>
      state.organizations.find(
        (organization) => organization.id === state.selectedOrganization
      ),
    organizations: (state) => state.organizations,
    flags: (state, getters) => getters.selectedOrganization?.Flags || [],
    hasFlag: (state, getters) => (flag, value = true) =>
      hasFlag.hasFlag(getters.flags, flag, value),
    flagValue: (state, getters) => (flag) => hasFlag.flagValue(getters.flags, flag),



    // Parties
    parties: (state) => {
      if (!state.selectedOrganization) return [];
      return state.parties[state.selectedOrganization] || [];
    },
    partyById: (state) => (partyId) => {
      if (!state.selectedOrganization) return null;
      return state.parties[state.selectedOrganization].find(
        (p) => p.id === partyId
      );
    },

    // Memberships
    memberships: (state) => {
      if (!state.selectedOrganization) return [];
      return state.memberships[state.selectedOrganization] || [];
    },
    membershipById: (state) => (membershipId) => {
      if (!state.selectedOrganization) return null;
      return state.memberships[state.selectedOrganization].find(
        (m) => m.id === membershipId
      );
    },
  },
  mutations: {
    setOrganizations(state, organizations) {
      state.organizations = organizations;
    },
    selectOrganization(state, org_id) {
      state.selectedOrganization = org_id;
    },
    setParties(state, parties) {
      if (!state.selectedOrganization) return;
      Vue.set(state.parties, state.selectedOrganization, parties);
    },
    setParty(state, party) {
      if (!state.selectedOrganization) return;
      const cacheParty = state.parties[state.selectedOrganization]
      if (!cacheParty) {
        state.parties[state.selectedOrganization] = [party];
        return;
      }

      const index = cacheParty.findIndex(
        (p) => p.id === party.id
      );
      if (index === -1) {
        state.parties[state.selectedOrganization].push(party);
      } else {
        Vue.set(state.parties[state.selectedOrganization], index, party);
      }
    },
    setMemberships(state, memberships) {
      if (!state.selectedOrganization) return;
      Vue.set(state.memberships, state.selectedOrganization, memberships);
    },
  },
  actions: {
    async selectOrganization({ commit }, org_id) {
      return commit("selectOrganization", org_id);
    },
    async getAllOrganizations({ commit }) {
      const response = await ORGANIZATION.getAll();
      commit("setOrganizations", response.organizations);
      return response.organizations;
    },

    // Parties
    async partyById({ state, commit }, partyId) {
      if (!state.selectedOrganization) return null;

      const { party } = await ORGANIZATION.party.getOne(
        state.selectedOrganization,
        partyId
      );
      commit("setParty", party);
      return party;
    },
    async updateParties({ commit, state }) {
      if (!state.selectedOrganization) return;
      const response = await ORGANIZATION.party.getAll(
        state.selectedOrganization
      );
      commit("setParties", response.parties);
      return response.parties;
    },

    // Memberships
    async updateMemberships({ commit, state }) {
      if (!state.selectedOrganization) return;
      const response = await MEMBERSHIP.getAll(state.selectedOrganization);
      commit("setMemberships", response.memberships);
      return response.memberships;
    },
  },
};
