export default [
  {
    path: "/verify",
    component: () => import("@/views/global/Clear"),
    meta: { layout: "auth" },
    children: [
      {
        path: "ticket/:id",
        name: "verify.ticket",
        component: () => import("@/views/proxy/TicketVerify"),
      },
      {
        path: "membership/:id",
        name: "verify.membership",
        component: () => import("@/views/proxy/MembershipVerify"),
      },
      {
        path: "*",
        redirect: "/",
      },
    ],
  },
  {
    path: "/v",
    children: [
      {
        path: "t/:id",
        redirect: "/verify/ticket/:id",
      },
      {
        path: "m/:id",
        redirect: "/verify/membership/:id",
      },
      {
        path: "*",
        redirect: "/verify",
      },
    ],
  },
];
