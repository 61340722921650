import http from "@/http-common";

const create = (orgId, partyId, file, options) => {
  if (!file) throw new Error("file is required");

  var formData = new FormData();
  formData.append("file", file);
  formData.append("options", JSON.stringify(options));

  return http
    .post(`/admin/organization/${orgId}/party/${partyId}/map`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((r) => r.data)
    .catch((e) => {
      throw e.response.data || e;
    });
};
const update = (orgId, partyId, options) => {
  return http
    .put(`/admin/organization/${orgId}/party/${partyId}/map`, options)
    .then((r) => r.data)
    .catch((e) => {
      throw e.response.data || e;
    });
};

const get = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/map`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

// Position
const positionCreate = (orgId, partyId, file, data) => {
  if (!file) throw new Error("file is required");

  var formData = new FormData();
  formData.append("file", file);
  formData.append("data", JSON.stringify(data));

  return http
    .post(`/admin/organization/${orgId}/party/${partyId}/map/position`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((r) => r.data)
    .catch((e) => {
      throw e.response.data || e;
    });
};

const positionSetDefault = (orgId, partyId, positionId) => {
  return http
    .put(`/admin/organization/${orgId}/party/${partyId}/map/position/${positionId}/default`)
    .then((r) => r.data)
    .catch((e) => {
      throw e.response.data || e;
    });
};

const positionDelete = (orgId, partyId, positionId) => {
  return http
    .delete(`/admin/organization/${orgId}/party/${partyId}/map/position/${positionId}`)
    .then((r) => r.data)
    .catch((e) => {
      throw e.response.data || e;
    });
};

export default {
  get,
  create,
  update,

  position: {
    create: positionCreate,
    setDefault: positionSetDefault,
    delete: positionDelete
  }
};
