import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";

import auth from "./modules/auth";
import organization from "./modules/organization.js";
import app from "./modules/app.js";

// DELETE
import largeSidebar from "./modules/largeSidebar";
import compactSidebar from "./modules/compactSidebar";
import themeConfig from "./modules/themeConfig";
import verticalSidebar from "./modules/verticalSidebar";
// DELETE

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    auth,
    organization,
    app,

    largeSidebar,
    compactSidebar,
    themeConfig,
    verticalSidebar,
  },
  plugins: [createPersistedState({ storage: window.localStorage })],
});
