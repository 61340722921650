import Vue from "vue";
import Vuex from "vuex";
import TICKET from "@/services/app/ticket";
import MEMBERSHIP from "@/services/app/membership";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    tickets: [],
    filiations: [],
  },
  getters: {
    tickets: (state) => state.tickets,
    filiations: (state) => state.filiations,
  },
  mutations: {
    setTickets(state, { tickets }) {
      state.tickets = tickets;
    },
    setFiliations(state, { filiations }) {
      state.filiations = filiations;
    },
    logout(state) {
      state.tickets = [];
      state.filiations = [];
    },
  },
  actions: {
    async logout({ commit }) {
      commit("logout");
    },
    async getTicketById({ state }, id) {
      try {
        const response = await TICKET.getById(id);
        if (!response.ticket) throw new Error("Ticket não encontrado");
        return {
          type: "online",
          ...response,
        };
      } catch (error) {
        const ticket = state.tickets.find((t) => t.id === id);
        if (!ticket) throw error;
        return {
          type: "cache",
          ticket,
        };
      }
    },
    async updateTickets({ commit, state }) {
      const response = await TICKET.getTickets();
      commit("setTickets", { tickets: response.tickets });
      return {
        type: "online",
        tickets: response.tickets,
      };
    },
    async updateFiliations({ commit, state }) {
      const response = await MEMBERSHIP.myFiliations();
      commit("setFiliations", { filiations: response.filiations });
      return {
        type: "online",
        filiations: response.filiations,
      };
    },
  },
};
