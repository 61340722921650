<template>
  <!-- box-shadow: 0 2px 4px 0 hsl(198deg 45% 10% / 12%) -->
  <v-card
    class="mx-auto"
    style="
      box-shadow: 0 8px 32px rgb(47 60 74 / 1%), 0 8px 16px rgb(47 60 74 / 2%);
    "
    :class="{ 'pa-4': padding, cardClass }"
    :dark="$vuetify.theme.dark"
    max-width="100%"
  >
    <slot name="progress">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
    </slot>

    <v-skeleton-loader
      height="100%"
      width="100%"
      type="card"
      :loading="getThemeMode.isLoading"
    >
      <slot />
    </v-skeleton-loader>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Card",

  props: {
    color: {
      type: String,
      default: "white",
    },
    cardClass: {
      type: String,
      default: "",
    },

    padding: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
  },
};
</script>
