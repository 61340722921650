<template>
  <div :id="holder" class="text-start text--black" />
</template>

<script>
import Viewer from "@toast-ui/editor/dist/toastui-editor-viewer";
import "@toast-ui/editor/dist/i18n/pt-br";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor/dist/theme/toastui-editor-dark.css";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";

export default {
  name: "base-editor",
  props: {
    holder: {
      type: String,
      default: () => "toast-viewer",
      require: true,
    },
    options: {
      type: Object,
      default: () => ({}),
      require: true,
    },
    initialized: {
      type: Function,
      default: () => {},
    },
    value: {
      type: String,
      require: true,
    },
  },
  data: () => ({
    viewer: null,
  }),
  methods: {
    initEditor(props) {
      this.destroyEditor();
      this.viewer = new Viewer({
        theme: this.theme,
        el: document.querySelector(`#${this.holder}`),
        ...props,
      });
      if (this.initialized) this.initialized(this.viewer);
    },

    destroyEditor() {
      if (this.viewer) {
        this.viewer.destroy();
        this.viewer = null;
      }
    },
  },
  computed: {
    theme() {
      return this.$vuetify.theme.isDark ? "dark" : "default";
    },
  },
  watch: {
    theme() {
      this.initEditor();
    },
    value: {
      handler() {
        this.initEditor({ ...this.options, initialValue: this.value });
      },
    },
  },
  mounted() {
    this.initEditor({ ...this.options, initialValue: this.value });
  },
};
</script>
