import http from "../../http-common";

const lastLogins = async () => {
  return await http
    .get(`/account/logins`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const importPhotoWhatsapp = async () => {
  return await http
    .put(`/account/photo/whatsapp`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const removePhoto = async () => {
  return await http
    .delete(`/account/photo`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const sessionGetLasts = async () => {
  return await http
    .get(`/account/sessions`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const sessionRemove = async (id) => {
  return await http
    .delete(`/account/session/${id}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const verify = async (base64) => {
  const formData = new FormData();
  formData.append("base64", base64);

  return await http
    .post(`/account/face/verify`, formData)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const save = async (base64, token) => {
  const formData = new FormData();
  formData.append("base64", base64);
  formData.append("token", token);

  return await http
    .post(`/account/face`, formData)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const remove = async (id) => {
  return await http
    .post(`/account/face/remove`, { id })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
  
const update = async (data) => {
  return await http
    .put(`/account/`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  lastLogins,
  importPhotoWhatsapp,
  removePhoto,
  face: {
    save,
    verify,
    remove,
  },
  update,
  session: {
    getLated: sessionGetLasts,
    remove: sessionRemove,
  },
};
