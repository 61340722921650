import http from "@/http-common";

const getTickets = async () => {
  return await http
    .get(`/app/ticket`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getParties = async () => {
  return await http
    .get(`/app/ticket/parties`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getById = async (id) => {
  return await http
    .get(`/app/ticket/${id}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getPayment = async (id) => {
  return await http
    .get(`/app/ticket/${id}/pay`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const pay = async (id, body) => {
  return await http
    .post(`/app/ticket/${id}/pay`, body)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const transfer = async (id, data) => {
  return await http
    .put(`/app/ticket/${id}/transfer`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const whatsappGroupJoin = async (id) => {
  return await http
    .put(`/app/ticket/${id}/whatsappGroup/join`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const refoundInfo = async (id) => {
  return await http
    .get(`/app/ticket/${id}/refound`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const requestRefound = async (id, body) => {
  return await http
    .put(`/app/ticket/${id}/refound`, body)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  getTickets,
  getParties,
  getById,
  getPayment,
  pay,
  transfer,
  whatsappGroupJoin,
  refoundInfo,
  requestRefound,
};
